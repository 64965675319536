import { generatePath } from 'react-router-dom';
var agencies = 'agencies';
var agencyId = ':agencyId';
var serviceFeeId = ':serviceFeeId';
var commercialInfo = 'commercial-info';
var creditInfo = 'credit-info';
var balanceCreditDetails = 'balance-credit-details';
var balanceCreditCardDetails = 'balance-credit-card-details';
var commissions = 'agency-commissions';
var create = 'create';
var edit = 'edit';
var info = 'info';
var search = 'search';
var serviceFee = 'service-fee';
var userId = ':userId';
var feeId = ':feeId';
var commissionId = ':commissionId';
var airlineCommissionId = ':airlineCommissionId';
var users = 'users';
var addFee = 'add-fee';
var accessDenied = 'access-denied';
var airlinesCommissions = 'airlines-commissions';
var rules = 'rules';
var sort = 'sort';
var ruleId = ':ruleId';
var duplicate = 'duplicate';
var markup = 'markup';
var branches = 'branches';
var markupId = ':markupId';
var addAirline = 'add-airline';
var airlineMarkupId = ':airlineMarkupId';
var title = 'title';
var branchId = ':branchId';
var modules = 'modules';
var formOfPayment = 'form-of-payment';
var searchModules = 'search-modules';
var searchModuleId = ':searchModuleId';
var creditCard = 'credit-card';
// TODO: ajustar os parâmetros das rotas
export var routes = {
    gotoRule: function (id) {
        return generatePath("".concat(rules, "/").concat(ruleId), { ruleId: String(id) });
    },
    goToAgency: function (id) {
        return generatePath("/".concat(agencies, "/:id"), { id: String(id) });
    },
    goToEditAgencyInfo: function (id) {
        return generatePath("/".concat(agencies, "/:id/").concat(info), { id: String(id) });
    },
    goToCommissions: function () { return "/".concat(commissions); },
    goToCommissionModule: function (commissionId) {
        return generatePath("/".concat(commissions, "/:commissionId"), {
            commissionId: String(commissionId),
        });
    },
    goToCreateAgency: function () { return "/".concat(agencies, "/").concat(create); },
    goToEditAgencyServiceFee: function (id) {
        return generatePath("/".concat(agencies, "/:id/").concat(serviceFee), { id: String(id) });
    },
    goToServiceFee: function () { return "/".concat(serviceFee); },
    goToServiceFeeModule: function (feeId) {
        return generatePath("/".concat(serviceFee, "/:feeId"), { feeId: String(feeId) });
    },
    goToEditAgencyCommercialInfo: function (id) {
        return generatePath("/".concat(agencies, "/:id/").concat(commercialInfo), {
            id: String(id),
        });
    },
    goToEditAgencyFormOfPayment: function (id) {
        return generatePath("/".concat(agencies, "/:id/").concat(formOfPayment), {
            id: String(id),
        });
    },
    goToEditAgencyCreditInfo: function (id) {
        return generatePath("/".concat(agencies, "/:id/").concat(creditInfo), {
            id: String(id),
        });
    },
    goToEditAgencyCreditCardInfo: function (id) {
        return generatePath("/".concat(agencies, "/:id/").concat(creditCard), {
            id: String(id),
        });
    },
    goToAgencyBalanceCreditDetails: function (id) {
        return generatePath("/".concat(agencies, "/:id/").concat(balanceCreditDetails), {
            id: String(id),
        });
    },
    goToAgencyBalanceCreditCardDetails: function (id) {
        return generatePath("/".concat(agencies, "/:id/").concat(balanceCreditCardDetails), {
            id: String(id),
        });
    },
    goToAgencyUsers: function (id) {
        return generatePath("/".concat(agencies, "/:id/").concat(users), { id: String(id) });
    },
    goToAgencyUser: function (agencyId, userId) {
        return generatePath("/".concat(agencies, "/:agencyId/").concat(users, "/:userId"), {
            agencyId: String(agencyId),
            userId: String(userId),
        });
    },
    goToCreateAgencyUser: function (id) {
        return generatePath("/".concat(agencies, "/:id/").concat(users, "/").concat(create), {
            id: String(id),
        });
    },
    goToEditAgencyUser: function (agencyId, userId) {
        return generatePath("/".concat(agencies, "/:agencyId/").concat(users, "/:userId/").concat(edit), {
            agencyId: String(agencyId),
            userId: String(userId),
        });
    },
    goToConsolidFeeModules: function () { return "/".concat(serviceFee); },
    goToAgencies: function () { return "/".concat(agencies); },
    goToAirlineCommissions: function () { return "/".concat(airlinesCommissions); },
    goToAirlineCommissionsModule: function (companyId) {
        return generatePath("/".concat(airlinesCommissions, "/:companyId"), {
            companyId: String(companyId),
        });
    },
    goToSearchModules: function () { return "/".concat(searchModules); },
    goToSearchModule: function (searchModuleId) {
        return generatePath("/".concat(searchModules, "/:searchModuleId"), {
            searchModuleId: String(searchModuleId),
        });
    },
    goToMarkups: function () { return "/".concat(markup); },
    goToBranches: function () { return "/".concat(branches); },
    goToMarkupModule: function (markupId) {
        return generatePath("/".concat(markup, "/").concat(markupId), {
            markupId: markupId,
        });
    },
    goToBranch: function (branchId) {
        return generatePath("/".concat(branches, "/:branchId"), {
            branchId: String(branchId),
        });
    },
    goToMarkupEditAirlineModule: function (markupId, airlineMarkupId) {
        return generatePath("/".concat(markup, "/").concat(markupId, "/").concat(edit, "/").concat(airlineMarkupId), {
            markupId: markupId,
            airlineMarkupId: airlineMarkupId,
        });
    },
    goToMarkupEditTitle: function (markupId) {
        return generatePath("/".concat(markup, "/").concat(markupId, "/").concat(edit, "/").concat(title), {
            markupId: markupId,
        });
    },
    goToBranchEditInfo: function (branchId) {
        return generatePath("/".concat(branches, "/").concat(branchId, "/").concat(info), {
            branchId: branchId,
        });
    },
    goToBranchEditModules: function (branchId) {
        return generatePath("/".concat(branches, "/").concat(branchId, "/").concat(modules), {
            branchId: branchId,
        });
    },
    agencies: agencies,
    agencyId: agencyId,
    serviceFeeId: serviceFeeId,
    commercialInfo: commercialInfo,
    creditInfo: creditInfo,
    commissions: commissions,
    create: create,
    edit: edit,
    balanceCreditDetails: balanceCreditDetails,
    balanceCreditCardDetails: balanceCreditCardDetails,
    airlinesCommissions: airlinesCommissions,
    airlineCommissionId: airlineCommissionId,
    rules: rules,
    ruleId: ruleId,
    sort: sort,
    addRule: "".concat(rules, "/").concat(create),
    duplicate: duplicate,
    info: info,
    search: search,
    serviceFee: serviceFee,
    users: users,
    userId: userId,
    searchModuleId: searchModuleId,
    feeId: feeId,
    commissionId: commissionId,
    addFee: addFee,
    accessDenied: accessDenied,
    markup: markup,
    branches: branches,
    markupId: markupId,
    addAirline: addAirline,
    airlineMarkupId: airlineMarkupId,
    title: title,
    branchId: branchId,
    modules: modules,
    formOfPayment: formOfPayment,
    searchModules: searchModules,
    creditCard: creditCard,
};
